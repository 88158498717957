.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .button {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.button-arrow {
  display: inline-block;
  font-family: 'QuicheSansMedium', serif;
  font-size: 24px;
  line-height: 1.25;
  hyphens: auto;
  cursor: pointer;
  color: var(--s-textcolor);
  padding: 15px 0 15px 98px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  border: none;
  transition: all 0.4s;
  position: relative;
}
.button-arrow:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 17px;
  top: 0;
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.button-arrow:after {
  content: '';
  width: 56px;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 16px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.button-arrow:hover,
.button-arrow:focus {
  transform: translateX(40px);
}
.button-arrow:hover:before,
.button-arrow:focus:before {
  opacity: 0.3;
}
@media (max-width: 1023px) {
  .button-arrow {
    font-size: 19px;
    line-height: 1.26315789;
    padding: 8px 0 8px 64px;
  }
  .button-arrow:before {
    width: 40px;
    height: 40px;
    left: 12px;
  }
  .button-arrow:after {
    width: 38px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 10px;
  }
  .button-arrow:hover,
  .button-arrow:focus {
    transform: translateX(22px);
  }
}
.mobile-navigation div.sub2 {
  display: flex;
  flex-wrap: wrap;
  gap: 150px 20px;
  margin-top: 125px;
}
.mobile-navigation div.sub2 > .item {
  padding: 0;
  border: 0;
  width: calc((100% - 60px) / 4);
}
.mobile-navigation div.sub2 > .item:hover > .menu,
.mobile-navigation div.sub2 > .item:focus > .menu {
  color: var(--s-linkcolor-hov);
}
.mobile-navigation div.sub2 > .item.exit {
  border: 0;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 34px;
  line-height: 1.35294118;
  padding-right: 0;
  transition: all 0.4s;
}
.mobile-navigation div.sub3 > .item > .menu {
  font-size: 21px;
  line-height: 1.47619048;
}
.mobile-navigation div.sub3 > .item > .menu:hover,
.mobile-navigation div.sub3 > .item > .menu:focus {
  text-decoration: underline;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
}
.home {
  height: 104px;
}
.container--head {
  margin: 20px 0 54px;
}
.headpart {
  justify-content: flex-start;
}
.headpart--one {
  width: calc(50% - 97px);
}
.headpart--two {
  width: 194px;
}
.headpart--three {
  width: calc(50% - 97px);
}
.langnavi--header {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.langnavi--header .sub1 {
  display: flex;
  gap: 10px;
}
.langnavi--header .sub1 > .item > .menu {
  font-size: 14px;
  line-height: 1.21428571;
  text-decoration: none;
}
.langnavi--header .sub1 > .item > .menu.path {
  text-decoration: underline;
}
.serviceLinks--desktop {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 19px;
}
.part--headerIconLinks {
  margin-left: 16px;
}
@media (max-width: 1200px) {
  .part--headerIconLinks {
    margin-left: 0;
  }
}
.section--one,
.section--two,
.section--three,
.section--four,
.section--five,
.section--six,
.section--seven,
.section--eight,
.section--nine {
  padding: calc(var(--spaceTotal) * 2) 0;
}
.layout1 .section--two {
  padding-top: calc(var(--spaceTotal) * 2);
}
.layout4 .section--area1 {
  padding-top: var(--spaceTotal);
}
.eventpart--2 {
  width: 50%;
}
.eventpart--3 {
  width: 50%;
  padding-left: 150px;
}
@media (max-width: 1500px) {
  .eventpart--3 {
    padding-left: 50px;
  }
}
.container--newsletter {
  margin: 100px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.newsletter__content {
  width: 66%;
}
.newsletter__link {
  width: auto;
}
.cbdModule--newsletterText {
  margin-top: var(--spacePart);
}
.cbdModule--newsletterLink {
  margin-top: 0;
}
.serviceLinks--mobile {
  display: none;
}
.layout3 .container--mood {
  flex-direction: row;
  gap: 40px;
}
.layout1 .moodbackground {
  height: 613px;
}
.layout2 .moodbackground {
  height: calc(100% - 650px);
}
.layout3 .moodbackground {
  height: calc(100% - 174px);
}
.layout1 .mood {
  height: 900px;
}
.layout2 .mood {
  height: 975px;
  padding: 0 90px;
}
.layout3 .mood {
  width: calc(50% - 20px);
  height: 790px;
}
.layout1 .content {
  max-width: 900px;
  top: 160px;
}
.layout2 .content {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 160px 90px 174px;
}
.layout2 .content #head {
  width: calc(50% - 20px);
}
.layout2 .content .unit--facilities {
  max-width: 374px;
}
.layout3 .content {
  width: calc(50% - 20px);
  padding: 160px 0 174px;
}
.layout4 .content {
  max-width: 926px;
  padding: 80px 0 100px;
}
.cbdModule--introText {
  margin-top: 50px;
}
.cbdModule--voucherCta {
  right: 55px;
  bottom: 28px;
}
.cbdModule--voucherCta .open {
  width: 165px;
  height: 165px;
  padding: 80px 10px 40px 10px;
  font-size: 24px;
  line-height: 1.16666667;
}
.cbdModule--voucherCta .open:before {
  top: 35px;
  width: 40px;
  height: 40px;
}
.footarea {
  margin: 60px 0 30px;
}
#edit .footarea {
  margin-bottom: 100px;
}
.footunit {
  flex-direction: row;
  gap: 0 calc(100% / 1616 * 40);
}
.footpart {
  text-align: left;
  align-items: flex-start;
}
.footunit--one .footpart--one {
  width: calc(100% / 1616 * 292);
}
.footunit--one .footpart--two {
  width: calc(100% / 1616 * 404);
}
.footunit--one .footpart--three {
  width: calc(100% / 1616 * 288);
}
.footunit--one .footpart--four {
  width: calc(100% / 1616 * 215);
}
.footunit--one .footpart--five {
  width: calc(100% / 1616 * 258);
}
.footunit--two .footpart--one {
  width: calc(100% / 1616 * 215);
}
.footunit--two .footpart--two {
  width: calc(100% / 1616 * 258);
}
.footlogo {
  height: 104px;
}
@media (max-width: 1450px) {
  .footlogo {
    height: 74px;
  }
}
.part--socialLinks .iconLinks__content {
  justify-content: flex-start;
}
.unit--legal .unit__body {
  justify-content: flex-end;
}
.langnavi--footer .sub1 {
  justify-content: flex-start;
}
.area--one .unitOne .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}
.area--one .unitOne--1-1 .part--slim {
  width: calc(50% - 20px);
}
.area--one .unitOne--1-1wide .part--slim {
  width: calc(25% - (120px / 4));
}
.area--one .unitTwo {
  flex-direction: row;
}
.area--one .unitTwo .unit__content {
  padding-top: calc(150px - var(--spacePart));
}
.area--one .unitTwo .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}
.area--one .unitTwo .part--slim.link {
  width: calc(50% - (100px / 2));
}
.area--one .unitTwo--imageLeft .unit__content {
  margin-left: auto;
  padding-left: 150px;
}
@media (max-width: 1500px) {
  .area--one .unitTwo--imageLeft .unit__content {
    padding-left: 50px;
  }
}
.area--one .unitTwo--imageRight .unit__content {
  padding-right: 150px;
}
@media (max-width: 1500px) {
  .area--one .unitTwo--imageRight .unit__content {
    padding-right: 50px;
  }
}
.area--one .unitTwo--variantOne .unit__background {
  width: 50%;
}
.area--one .unitTwo--variantOne .unit__content {
  width: 50%;
}
.area--one .unitTwo--variantTwo .unit__background {
  width: calc(50% - 100px);
  margin-left: 100px;
  max-width: 500px;
}
.area--one .unitTwo--variantTwo .unit__content {
  width: 50%;
}
.area--one .unitThree {
  flex-direction: row;
  align-items: center;
}
.area--one .unitThree .unit__background {
  width: 60%;
  min-height: 100%;
  height: 650px;
}
.area--one .unitThree .unit__background * {
  height: 100%;
}
.area--one .unitThree .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitThree .unit__background .placeholder {
  padding-bottom: 0;
}
.area--one .unitThree .unit__content {
  width: 40%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 98px;
  padding-right: 50px;
}
.area--one .unitThree .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}
.area--one .unitThree .part--slim.link {
  width: calc(50% - (100px / 2));
}
@media (max-width: 1500px) {
  .area--one .unitThree .unit__background {
    width: 40%;
  }
  .area--one .unitThree .unit__content {
    width: 60%;
  }
}
.area--one .unitThree--imageLeft .unit__content {
  margin-left: auto;
}
.area--one .unitThree--imageRight .unit__background {
  order: 2;
}
.area--one .unitThree--imageRight .unit__content {
  order: 1;
}
.area--one .unitFour {
  flex-direction: row;
  align-items: center;
  min-height: 808px;
}
.area--one .unitFour .unit__content {
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 98px;
  padding-right: calc(50% + 98px);
}
@media (max-width: 1500px) {
  .area--one .unitFour .unit__content {
    padding-left: 50px;
    padding-right: calc(50% + 50px);
  }
}
.area--one .unitFour .part--imageSlider {
  width: 50%;
  height: 100%;
}
.area--one .unitFour .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}
.area--one .unitFour .part--slim.link {
  width: calc(50% - (100px / 2));
}
.area--one .unitFive .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 92px;
}
.area--one .unitFive .part {
  width: calc(100% / 3 - (92px * 2 / 3));
}
.area--one .unitFive .part + .part + .part + .part {
  margin-top: calc((var(--spacePart) + 1px) * -1);
}
.area--one .unitSix .unit__content {
  padding: var(--spacePart) 60px;
}
.area--one .unitSix .more {
  padding-right: 120px;
}
.area--one .unitSix .more .part:last-child {
  margin-bottom: calc(64px - var(--spacePart));
}
.area--one .unitSix .less,
.area--one .unitSix .more {
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}
.area--one .unitSix .part {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}
.area--one .unitSix .part--slim {
  width: calc(50% - 40px);
}
.area--one .unitSix .fold-toggle {
  padding-right: 120px;
}
.area--one .unitSix .fold-toggle:before {
  width: 60px;
  height: 60px;
}
.area--one .unitSix .fold-toggle:after {
  top: -17px;
  width: 27px;
  height: 56px;
  margin-left: 17px;
  margin-right: 17px;
}
#view .area--one .unitSix.unit--foldOpen .fold-toggle:after {
  top: 17px;
}
.area--one .unit--form .unit__content {
  padding: 86px 134px;
}
.area--two .unitOne .unit__content {
  justify-content: flex-start;
  min-height: 667px;
  padding: calc(23px - var(--spacePart)) 27px;
}
.area--two .unitOne .unit__content:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.47), transparent);
}
.area--three .unitOne {
  padding-left: 40px;
  padding-right: 122px;
}
.area--three .unitOne.unit--isAnchor:before {
  width: 60px;
  height: 60px;
  right: 17px;
}
.area--three .unitOne.unit--isAnchor:after {
  width: 56px;
  height: 28px;
  right: 44px;
}
/*# sourceMappingURL=./screen-large.css.map */